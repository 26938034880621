var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{staticStyle:{"width":"60%","margin":"0 auto"},attrs:{"form":_vm.form}},[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":10,"offset":5}},[_c('a-form-item',{attrs:{"label":"库位名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]),expression:"['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]),expression:"['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"仓库名称"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'wms_warehouse_id',
              {
                rules: [
                  { required: true, message: '请选择' },
                ],
              },
            ]),expression:"[\n              'wms_warehouse_id',\n              {\n                rules: [\n                  { required: true, message: '请选择' },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"选择"}},_vm._l((this.wms_warehouses),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":val.id}},[_vm._v(_vm._s(val.name))])}),1)],1)],1)],1),_c('a-form-item',_vm._b({staticStyle:{"width":"60%","margin":"0 auto"}},'a-form-item',_vm.buttonCol,false),[_c('a-row',[_c('a-col',{attrs:{"span":"6"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1),_c('a-col',{attrs:{"span":"10"}},[_c('a-button',{on:{"click":_vm.handleGoBack}},[_vm._v("返回")])],1),_c('a-col',{attrs:{"span":"8"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }