import request from '@/utils/request'
const requestApi = {
  wms_warehouse_list: '/wms/warehouse/',
  wms_warehouse_create: '/wms/warehouse/',
  wms_warehouse_update: '/wms/warehouse/',
  wms_warehouse_delete: '/wms/warehouse/'
}

export function warehouse_list (query) {
  return request({
    url: requestApi.wms_warehouse_list,
    method: 'get',
    params: query
  })
}
export function warehouse_list_id (query) {
  return request({
    url: requestApi.warehouse_list,
    method: 'get',
    params: query
  })
}

export function warehouse_create (data) {
  return request({
    url: requestApi.wms_warehouse_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function warehouse_update (data, warehouse_id) {
  return request({
    url: requestApi.wms_warehouse_update + warehouse_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function warehouse_delete (warehouse_id) {
  return request({
    url: requestApi.wms_warehouse_delete + warehouse_id + '/',
    method: 'delete'
  })
}

export function commonStoreConfig (parameter) {
  return request({
    url: '/wms/common_warehouse/corporation_config/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
