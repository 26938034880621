<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="编号" >
              <a-input v-decorator="['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="库位名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"/>
            </a-form-item>
            <!--            <a-form-item label="仓库名称">-->
            <!--              <a-select-->
            <!--                v-decorator="[-->
            <!--                  'wms_warehouse_id',-->
            <!--                  {-->
            <!--                    rules: [-->
            <!--                      { required: true, message: '请选择' },-->
            <!--                    ],-->
            <!--                  },-->
            <!--                ]"-->
            <!--                placeholder="选择"-->
            <!--              >-->
            <!--                <a-select-option :key="key" :value="val.id" v-for="(val, key) in this.wms_warehouses" :disabled="val.id !== model.wms_warehouse_id">{{ val.name }}</a-select-option>-->
            <!--              </a-select>-->
            <!--            </a-form-item>-->
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { warehouse_list_id } from '@/api/c_wms_warehouse'

// 表单字段
const fields = ['id', 'name', 'wms_warehouse_name', 'code', 'wms_warehouse_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      wms_warehouses: []
    }
  },
  created () {
    console.log('custom modal created', this.model)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  mounted () {
    this.wmsWarehouseList()
  },
  methods: {
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    wmsWarehouseList () {
      warehouse_list_id().then(res => {
        this.wms_warehouses = res.data
      })
    }
  }
}
</script>
