<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" v-if="false">
        <a-row :gutter="24">
          <a-col :md="4" :sm="24">
            <a-form-item label="编号">
              <a-input v-model="queryParam.code" placeholder="请输入完整的编号" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="库位名称">
              <a-input v-model="queryParam.name" placeholder="请输入库位名称" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        :title="myrecord.name"
        sub-title="库位列表"
        @back="() => $router.go(-1)" />
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '@/views/c-wms/cargo_space/modules/CreateForm'
import { cargo_space_create, cargo_space_delete, cargo_space_list } from '@/api/c_wms_cargo_space'
import { Base64 } from 'js-base64'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      myrecord: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '编号',
          dataIndex: 'code'
        },
        {
          title: '库位名称',
          align: 'center',
          dataIndex: 'name'
        },
        // {
        //   title: '仓库名称',
        //   align: 'center',
        //   dataIndex: 'wms_warehouse'
        // },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return cargo_space_list(Object.assign(parameter, this.queryParam, { wms_warehouse_id: this.$route.params.id }))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.myrecord = JSON.parse(Base64.decode(this.$route.query.record || ''))
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleAdd () {
      this.mdl.wms_warehouse_name = this.myrecord.name
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleUpdate (record) {
      console.log(record)
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {
      cargo_space_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.wms_warehouse_id = this.myrecord.id
          cargo_space_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
